const possibleAuthCookies = [
  `${useRuntimeConfig().public.supabase.cookieName}-127-auth-token`,
  `${useRuntimeConfig().public.supabase.cookieName}-auth-token`,
  'sb-bbqijaqjrahmakotxpqo-auth-token',
  'sb-bbqijaqjrahmakotxpqo-auth-token.0',
  'sb-bbqijaqjrahmakotxpqo-auth-token.1',
  'sb-auth-token',
  'sb-auth',
];

export default defineNuxtRouteMiddleware(async (to, from) => {
  console.log('Executing landingpage-redirect middleware...');

  // Check if the current route is the root path '/'
  if (to.path === '/') {
    console.log('Current route is the root path (/)');

    // Find the first existing authentication cookie
    const authCookie = possibleAuthCookies.find((cookie) => {
      const value = useCookie(cookie).value;
      return value;
    });

    if (authCookie) {
      console.log('Authentication cookie found:', authCookie);

      // Get the redirect path from the cookie
      const redirectCookie = useCookie(`${useRuntimeConfig().public.supabase.cookieName}-redirect-path`);
      const redirectPath = redirectCookie.value || '/app/documents';
      redirectCookie.value = null;

      console.log('Redirecting to:', redirectPath);

      const router = useRouter();
      await router.push(redirectPath);
    } else {
      console.log('No authentication cookie found.');
    }
  } else {
    console.log('Current route is not the root path (/)');
  }
});
